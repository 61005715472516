import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Button, Hero, SectionHeader, Link } from "../components/common";

import Cookie from "../assets/images/vectors/cookie.svg";

import "./cookie.css";

function CookiePage({ location }) {
  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Cookie Policy"
        path={location?.pathname}
      />
      <section className="mt-24 mb-24">
        <Hero
          subTitle="We Care"
          title={
            <>
              Cookie Policy
              <br />
              (We Send Free Cookies)
            </>
          }
        />
      </section>
      <section className="mb-48">
        <div className="m-auto content dev-vg-cookie__container">
          <p className="text-ui-gray-plus-4 font-normal text-lg leading-8 tracking-tightsy mb-16">
            We use cookies to help improve your experience of our website at{" "}
            <Link to="/">{process.env.GATSBY_VG_SITE_URL}</Link>. This cookie
            policy is part of Vackground&rsquo;s privacy policy. It covers the
            use of cookies between your device and our site.
            <br />
            <br />
            We also provide basic information on third-party services we may
            use, who may also use cookies as part of their service. This policy
            does not cover their cookies.
            <br />
            <br />
            If you don’t wish to accept cookies from us, you should instruct
            your browser to refuse cookies from{" "}
            <Link to="/">https://vackground.com</Link>. In such a case, we may
            be unable to provide you with some of your desired content and
            services.
          </p>
          <h2
            className="text-ui-gray-plus-4 font-bold lg:text-4xl md:text-3.5xl text-2.25xl md:leading-11 leading-9 mb-8 lg:tracking-tightty md:tracking-tighteer tracking-tightie"
            style={{
              letterSpacing: "-2px",
            }}
          >
            What is a cookie?
          </h2>
          <br />
          <p className="text-ui-gray-plus-4 font-normal text-lg leading-8 tracking-tightsy mb-16">
            A cookie is a small piece of data that a website stores on your
            device when you visit. It typically contains information about the
            website itself, a unique identifier that allows the site to
            recognize your web browser when you return, additional data that
            serves the cookie’s purpose, and the lifespan of the cookie itself.
            <br />
            <br />
            Cookies are used to enable certain features (e.g. logging in), track
            site usage (e.g. analytics), store your user settings (e.g. time
            zone, notification preferences), and to personalize your content
            (e.g. advertising, language).
            <br />
            <br />
            Cookies set by the website you are visiting are usually referred to
            as first-party cookies. They typically only track your activity on
            that particular site.
            <br />
            <br />
            Cookies set by other sites and companies (i.e. third parties) are
            called third-party cookies They can be used to track you on other
            websites that use the same third-party service.
          </p>
          <h2
            className="text-ui-gray-plus-4 font-bold lg:text-4xl md:text-3.5xl text-2.25xl md:leading-11 leading-9 mb-8 lg:tracking-tightty md:tracking-tighteer tracking-tightie"
            style={{
              letterSpacing: "-2px",
            }}
          >
            Cookie types and usage
          </h2>
          <br />
          <br />
          <p className="text-ui-gray-plus-4 font-normal text-lg leading-8 tracking-tightsy mb-16">
            <strong>Essential Cookies:</strong> Essential cookies are crucial to
            your experience of a website, enabling core features like user
            logins, account management, shopping carts, and payment processing.
            <br />
            <br />
            We use essential cookies to enable certain functions on our website.
            <br />
            <br />
            <strong>Performance Cookies:</strong> Performance cookies track how
            you use a website during your visit. Typically, this information is
            anonymous and aggregated, with information tracked across all site
            users. They help companies understand visitor usage patterns,
            identify and diagnose problems or errors their users may encounter,
            and make better strategic decisions in improving their audience’s
            overall website experience. These cookies may be set by the website
            you’re visiting (first-party) or by third-party services. They do
            not collect personal information about you.
            <br />
            <br />
            We use performance cookies on our site.
            <br />
            <br />
            <strong>Functionality Cookies:</strong> Functionality cookies are
            used to collect information about your device and any settings you
            may configure on the website you’re visiting (like language and time
            zone settings). With this information, websites can provide you with
            customized, enhanced, or optimized content and services. These
            cookies may be set by the website you’re visiting (first-party) or
            by third-party services.
            <br />
            <br />
            We use functionality cookies for selected features on our site.
            <br />
            <br />
            <strong>Targeting/advertising cookies:</strong>{" "}
            Targeting/advertising cookies help determine what promotional
            content is most relevant and appropriate to you and your interests.
            Websites may use them to deliver targeted advertising or limit the
            number of times you see an advertisement. This helps companies
            improve the effectiveness of their campaigns and the quality of
            content presented to you. These cookies may be set by the website
            you’re visiting (first-party) or by third-party services.
            Targeting/advertising cookies set by third-parties may be used to
            track you on other websites that use the same third-party service.
            <br />
            <br />
            We use targeting/advertising cookies on our site.
          </p>
        </div>
      </section>
      <section className="mb-24">
        <div className="mb-8">
          <SectionHeader
            art
            artVector={Cookie}
            title="Have Questions or Concerns Regarding Our Cookies?"
          />
        </div>
        <div className="flex items-center justify-center">
          <Button to="/contact" hasMoustache icon="vg-mail" text="Contact Us" />
        </div>
      </section>
    </Layout>
  );
}

CookiePage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default CookiePage;
